

















import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
interface TabRoute {
  route: string;
  text: string;
}

@Component({
  name: "reports"
})
export default class Reports extends mixins(LoadingMixin, CurrentUserMixin) {
  activeTab: string;
  constructor() {
    super();
    this.activeTab = this.$route.path;
  }

  get routes(): TabRoute[] {
    const results: TabRoute[] = [
      {
        text: "Program History",
        route: "/reports/programs"
      },
      {
        text: "Private Lesson History",
        route: "/reports/private"
      },
      {
        text: "Drop Ins History",
        route: "/reports/drop-ins"
      }
    ];
    return results;
  }
}
